//Translation of Colors to background uses in OKQ8 from light mode perspective
export const BackgroundColorVariants = {
  variants: {
    bgColor: {
      variant: {
        backgroundColor: '$surfaceVariant',
      },
      subtle: {
        backgroundColor: '$decorationSubtle',
      },
      emphasized: {
        backgroundColor: '$decorationEmphasized',
      },
      inverse: {
        backgroundColor: '$inverse',
      },
    },
  },
};

export type BGColors = keyof typeof BackgroundColorVariants.variants.bgColor;

export enum BackgroundColorTypes {
  Emphasized = 'emphasized',
  Inverse = 'inverse',
  Subtle = 'subtle',
  Variant = 'variant',
}

//Translates BE prop name to matching FE props name
// export const colorTranslator = (beColor: BackgroundColorEnum) => {
//   let bgColor: BGColors = 'variant';
//   if (beColor) {
//     switch (+beColor) {
//       case BackgroundColorEnum.Variant:
//         bgColor = BackgroundColorTypes.Variant;
//         break;
//       case BackgroundColorEnum.Subtle:
//         bgColor = BackgroundColorTypes.Subtle;
//         break;
//       case BackgroundColorEnum.Emphasized:
//         bgColor = BackgroundColorTypes.Emphasized;
//         break;
//       case BackgroundColorEnum.Inverse:
//         bgColor = BackgroundColorTypes.Inverse;
//         break;
//       default:
//         bgColor = BackgroundColorTypes.Variant;
//         break;
//     }
//   }
//   return bgColor;
// };
