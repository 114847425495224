import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import CTAImageBlockModel from 'Models/Blocks/CTAImageBlock/CTAImageBlockModel.interface';
import { CTABlockCard, CTABlockImageContainer } from '../CTABlock/CTABlock';

type PropTypes = {
  content: CTAImageBlockModel;
};

function CTAImageBlock({ content: { image, link } }: PropTypes) {
  const isMobile = useMedia(mediaQueryTypes.bpMax720);

  return (
    <CTABlockCard>
      <InteractableContentLink href={link.href}>
        {image && (
          <CTABlockImageContainer
            src={(isMobile && image.mobileSrc) || image.src}
            alt={image.alt ? image.alt : ''}
            title={image.title}
          />
        )}
      </InteractableContentLink>
    </CTABlockCard>
  );
}

export default CTAImageBlock;
