import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import { styled } from 'Theme/stitches.config';
import { ContentAlignmentTypes, SizeTypes } from 'Shared/Types/types';
import InteractableContentLink, {
  TargetTypes,
} from 'Shared/Components/Links/InteractableContentLink';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import CTABlockModel from 'Models/Blocks/CTABlock/CTABlockModel.interface';
import { extraVisibleFocusStateStyle } from 'Shared/Common/focusState';

type PropTypes = {
  content: CTABlockModel;
  size?: 'm' | 's';
  isSingle?: boolean;
};

function CTABlock({
  content: {
    heading,
    image,
    link,
    text,
    logoImage,
    horizontalPosition,
    textMode = 'dark',
  },
  size,
}: PropTypes) {
  const isMobile = useMedia(mediaQueryTypes.bpMax720);

  return (
    <CTABlockCard>
      {image && (
        <CTABlockImageContainer
          src={(isMobile && image.mobileSrc) || image.src}
          alt={image.alt ? image.alt : ''}
          title={image.title}
        />
      )}
      <ContentContainer
        horizontalContentPosition={horizontalPosition as ContentAlignmentTypes}
      >
        <CTAContainer>
          <CTAContent isCentered={horizontalPosition === 'center'}>
            {logoImage && (
              <LogoImage
                src={logoImage.src}
                alt={logoImage.alt ? logoImage.alt : ''}
              />
            )}
            <Heading
              tag="h3"
              weights="bold"
              size={size as SizeTypes}
              color={textMode === 'dark' ? 'Regular' : 'OnInteractive'}
              css={HeadingStyle}
            >
              {heading}
            </Heading>
            {text && (
              <BodyText
                size="m"
                weight="regular"
                color={textMode === 'dark' ? 'primary' : 'oninteractive'}
                css={{
                  flexGrow: 1,
                }}
              >
                {text}
              </BodyText>
            )}
            {link && (
              <InteractableContentLink
                href={link.href}
                title={link.title}
                target={(link.target as TargetTypes) || '_self'}
                type="hero"
              >
                {link.text}
              </InteractableContentLink>
            )}
          </CTAContent>
        </CTAContainer>
      </ContentContainer>
    </CTABlockCard>
  );
}

export const CTABlockCard = styled('div', {
  position: 'relative',
  backgroundColor: '$surface',
  h: '100%',
  w: '100%',
  a: {
    '&:focus-visible': {
      outlineOffset: '2px',
      outline: '2px solid $colors$interactiveBorderActive',
      borderRadius: 2,
    },
  },
});

export const CTABlockImageContainer = styled('img', {
  w: '100%',
});

const ContentContainer = styled('div', {
  position: 'absolute',
  tblr: 0,
  w: '100%',
  h: '100%',
  display: 'flex',
  justifyContent: 'start',
  variants: {
    horizontalContentPosition: {
      left: {
        '@bpMin961': {
          justifyContent: 'start',
        },
      },
      center: {
        justifyContent: 'center',
      },
      right: {
        // Right is Desktop only default is left
        '@bpMin961': {
          justifyContent: 'end',
        },
        '@bpMax960': {
          justifyContent: 'center',
          textAlign: 'center',
          '& a': { margin: '0 auto' },
        },
      },
    },
  },
});

const CTAContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  alignItems: 'start',
  h: '100%',
  w: '100%',
  maxW: 420,
  p: '$s150',
  '@bpMin721': {
    p: '$s300',
  },
  '@bpMin961': {
    w: '80%',
  },
});

const CTAContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  g: 8,
  '& a:focus-visible': {
    ...extraVisibleFocusStateStyle,
  },
  '@bpMin961': {
    g: 14,
  },
  variants: {
    isCentered: {
      true: {
        alignItems: 'center',
        textAlign: 'center',
        '& a': { margin: '0 auto' },
      },
    },
  },
});

const LogoImage = styled('img', {
  width: 'auto',
  maxWidth: '100%',
  height: 'auto',
});

const HeadingStyle = {
  mb: '-$s50',
};

export default CTABlock;
